export const weatherCodeMap = new Map([
  [0, { label: "Clear Sky", icon: "01d" }],
  [1, { label: "Mainly Clear", icon: "01d" }],
  [2, { label: "Partly Cloudy", icon: "02d" }],
  [3, { label: "Overcast", icon: "03d" }],
  [45, { label: "Fog", icon: "50d" }],
  [48, { label: "Fog", icon: "50d" }],
  [51, { label: "Light Drizzle", icon: "09d" }],
  [53, { label: "Moderate Drizzle", icon: "09d" }],
  [55, { label: "Heavy Drizzle", icon: "09d" }],
  [56, { label: "Freezing Drizzle", icon: "09d" }],
  [57, { label: "Freezing Drizzle", icon: "096" }],
  [61, { label: "Slight Rain", icon: "10d" }],
  [63, { label: "Moderate Rain", icon: "09d" }],
  [65, { label: "Heavy Rain", icon: "09d" }],
  [66, { label: "Light Freezing Rain", icon: "09d" }],
  [67, { label: "Heavy Freezing Rain", icon: "09d" }],
  [71, { label: "Light Snow", icon: "13d" }],
  [73, { label: "Moderate Snow", icon: "13d" }],
  [75, { label: "Heavy Snow", icon: "13d" }],
  [77, { label: "Snow Grains", icon: "13d" }],
  [80, { label: "Light Rain Showers", icon: "10d" }],
  [81, { label: "Moderate Rain Showers", icon: "09d" }],
  [82, { label: "Heavy Rain Showers", icon: "09d" }],
  [85, { label: "Slight Snow Showers", icon: "13d" }],
  [86, { label: "Heavy Snow Showers", icon: "13d" }],
  [95, { label: "Thunderstorms", icon: "11d" }],
  [96, { label: "Thunderstorms", icon: "11d" }],
  [99, { label: "Thunderstorms", icon: "11d" }],
]);
